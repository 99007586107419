@import url("https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@400;500;600;700&display=swap");

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 300ms ease-in-out;
  scroll-behavior: smooth;
}
* {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
    color: #ccc;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid #ccc;
    border-radius: 4px;
    background: transparent;
  }
}

:root {
  --caudex: "Caudex", serif;
  --inter: "Inter", sans-serif;

  --black-tr: rgba(0, 0, 0, 0.6);
}

body {
  //   background-color: #101010;
  //   color: #ccc;
}

button.eng102_next {
  background: linear-gradient(100deg, #2d423b 0%, #375148 100%);
  border: none;
  outline: none;
  color: #ccc;
  font-size: inherit;
  padding: 12px min(30%, 100px);
  margin-left: auto;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 5px 5px 10px #121a18, -5px -5px 10px #121a18;
  &:disabled {
    filter: grayscale(100%);
    box-shadow: none;
  }
}
